@import '../../styles/brand-colors';

.alert-container {
  display: flex;

  .alert-message {
    flex-grow: 1;
    width: 100% !important;
    margin: 24px 24px 0 !important;
  }
}

.loading-information {
  height: 850px;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader-dots {
    position: relative;
    top: 0;
    left: 0;
  }
}

.holiday-edit-form-container {
  p {
    color: $dusk-light;
    font-size: 14px;
    width: 5vw;
  }

  .top-content {
    border: 1px solid $midnight-30;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 24px;
    padding: 32px;
    display: flex;
    flex-direction: row;
    min-width: 900px;

    .input-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 33%;

      .input {
        width: 22vw;
        border-radius: 4px;
        align-items: center;
        height: 35px;
      }
    }

    .select-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 33%;
      margin-left: 10px;

      .select {
        height: 40px;
        width: 22vw;
        border-radius: 4px;
        margin-top: 5px;
      }
    }
  }
}


.MuiFormControl-root {
  height: 37px !important;
}

.holiday-form-fields {
  border: 1px solid $midnight-30;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 24px;
  padding: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-width: 900px;

  .holiday-content-block1 {
    display: flex;
    flex-direction: column;
    width: 33%;

    .date-range-selector {
      display: flex;
      flex-direction: row;

      > div {
        &:nth-child(1) {
          flex-grow: 1;
          width: 66.6%;
        }

        &:nth-child(2) {
          flex-grow: 1;
          width: 33.4%;
          margin-right: 15px;
        }
      }

      .date-picker {
        & .MuiFormControl-root {
          display: contents;
          justify-content: center;
        }

        & .MuiInputBase-input {
          width: 80%;
          justify-content: center;
        }

        & .MuiInputAdornment-root {
          width: 20%;

          button {
            display: contents;
          }
        }
      }
    }

    .test-area-config {
      display: flex;
      flex-direction: row;

      textarea {
        color: $dusk-light;
        height: 106px;
        width: 22vw;
        border-radius: 4px;
        margin-top: 16px;
        font-size: 14px;
      }

      &:focus {
        outline: $midnight-30;
        border: 2px solid $midnight-30;
      }
    }
  }

  .holiday-content-block2 {
    display: flex;
    flex-direction: column;
    width: 33%;

    .select-group {
      display: flex;
      flex-direction: row;
      align-items: center;

      .select-size {
        height: 40px;
        width: 22vw;
        border-radius: 4px;

        .MuiAutocomplete-input {
          text-align: center;
        }
      }
    }
  }

  .holiday-content-block3 {
    display: flex;
    flex-direction: column;
    width: 33%;

    .switch-group {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    input {
      height: 35px;
      width: 2.5vw;
      border-radius: 4px;
    }
  }
}

@media screen and (max-width: 1500px) {
  .holiday-edit-form-container {
    .top-content {
      .input-container {
        flex-direction: column;

        p {
          width: 100%;
          text-align: center;
        }
      }

      .select-container {
        flex-direction: column;

        .select {
          margin-top: 0;
        }
      }
    }
  }
  .holiday-form-fields {
    p {
      width: 100%;
      text-align: center;
    }

    .holiday-content-block1 {
      .date-range-selector {
        flex-direction: column;

        > div {
          &:nth-child(1) {

          }

          &:nth-child(2) {
            flex-grow: 1;
            width: 100%;
          }
        }
      }

      .test-area-config {
        flex-direction: column;
        align-items: center;
      }
    }

    .holiday-content-block2 {
      .select-group {
        flex-direction: column;
      }
    }

    .holiday-content-block3 {
      .switch-group {
        flex-direction: column;
      }
    }
  }
  .date-range-pickers > div:nth-child(1) {
    width: 10vw;
  }
  .date-range-pickers > div:nth-child(2) {
    width: 10vw;
  }
}









