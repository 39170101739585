@charset "UTF-8";
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700,400italic');

@font-face {
  font-family: 'Wigrum';
  src: url('//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-light.eot');
  src: url('//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-light.eot?#iefix') format('embedded-opentype'),
  url('//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Wigrum';
  src: url('//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-medium.eot');
  src: url('//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-medium.eot?#iefix') format('embedded-opentype'),
  url('//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Wigrum';
  src: url('//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-bold.eot');
  src: url('//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-bold.eot?#iefix') format('embedded-opentype'),
  url('//d10xx36pi6qs9h.cloudfront.net/font/wigrumweb-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

body,
p,
button,
input,
select,
textarea,
blockquote {
  font-family: Nunito Sans, Helvetica, Arial, sans-serif !important;
  color: $gray-one;
  font-size: 16px;
  line-height: 24px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Wigrum, Helvetica, Arial, sans-serif !important;
  color: $gray-one;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

h1,
h2.h1,
h3.h1,
h4.h1,
h5.h1,
h6.h1,
.type-display-large {
  font-weight: 700;
  font-size: 40px;
  line-height: 72px;
  letter-spacing: -1.5px;
}

h2,
h1.h2,
h3.h2,
h4.h2,
h5.h2,
h6.h2,
.type-display-medium {
  font-weight: 700;
  font-size: medium;
  line-height: 56px;
  letter-spacing: -1.5px;
}

h3,
h1.h3,
h2.h3,
h4.h3,
h5.h3,
h6.h3,
.type-display-small {
  font-weight: 700;
  font-size: 38px;
  line-height: 40px;
  letter-spacing: -0.2px;
}

h4,
h1.h4,
h2.h4,
h3.h4,
h5.h4,
h6.h4,
.type-heading-large {
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.5px;
}

h5,
h1.h5,
h2.h5,
h3.h5,
h4.h5,
h6.h5,
.type-heading-medium {
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
  letter-spacing: -0.2px;
}

h6,
h1.h6,
h2.h6,
h3.h6,
h4.h6,
h5.h6,
.type-heading-small {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  &.small,
  .type-heading-small-caps {
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
}

p,
.caption,
label,
.label,
.type-body {
  font-size: 16px;
  line-height: 24px;
}

p {
  margin: 16px 0;
}

p.large,
li.large,
.caption.large,
span.large,
label.large,
.label.large,
.type-body-large {
  font-size: 21px;
  line-height: 32px;
}

p.small,
li.small,
.caption.small,
span.small,
label.small,
.label.small,
.type-body-small {
  font-size: 14px;
  line-height: 20px;
}

p.tiny,
li.tiny,
caption.tiny,
span.tiny,
label.tiny,
.label.tiny,
.type-body-tiny {
  font-size: 12px;
  line-height: 16px;
}

p.micro,
li.micro,
.caption.micro,
span.micro,
label.micro,
.label.micro,
.type-body-micro {
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
}

p.input-helper {
  margin-top: 4px;
  margin-bottom: 0;
}

label,
.label {
  font-weight: 600;
}

b,
strong {
  font-weight: bold;
}

.font-bold {
  font-weight: 700;
}

.font-regular {
  font-weight: 400;
}

.font-underline {
  text-decoration: underline;
}

a {
  color: $lake;
  &:hover {
    cursor: pointer;
  }
}

i {
  font-style: normal;
}

.MuiTypography-body1 {
  font-family: Nunito Sans, Helvetica, Arial, sans-serif !important;
}
