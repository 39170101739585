.sortable-header {
  position: relative;
  text-align: center;

  .hidden {
    opacity: 0.3;
  }

  .sort-icon {
    overflow: visible !important;
    align-content: center;
  }
}

.sortable-header:hover {
  .hidden {
    opacity: 1;
  }
}
