@import '../../styles/brand-colors';

.accordion-row {
  width: 100%;

  .accordion-summary {
    height: 50px;

    & .MuiAccordionSummary-content {
      border-bottom: 1px solid $midnight-40;
    }
  }

  .accordion-details {
    width: 100%;
    border-collapse: collapse;
    margin-top: -8px;
    margin-left: -15px;
    padding-bottom: 0;

    .table {
      width: 100%;
      background: #F5F7F8;
      border-collapse: collapse;

      .table-header {
        width: 100%;
        height: 24px;
        left: 914px;
        top: 271px;
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 45px;
        border-bottom: 1px solid $midnight-40;
      }

      .table-body {
        text-align: center;
        width: 100%;
        border-bottom: 1px solid $midnight-40;
        height: 35px;
        left: 1338px;
        top: 393px;
        font-family: Nunito Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 45px;
      }
    }
  }

  & .MuiAccordion-rounded:last-child {
    border-radius: 0;
  }

}

.MuiAccordionSummary-root.Mui-expanded {
  height: 50px;
  max-height: 50px;
  min-height: 50px !important;
  background: #E5EBED;

  .imagen-collapse {
    transform: rotate(90deg)
  }

  .add-date-button {
    visibility: visible;
  }
}

.ReactVirtualized__Table__headerRow {
  width: 15px;
  height: 24px;
  left: 105px;
  top: 366px;
}

.icon-container {
  padding-right: 15px;
}

.disabled-icon {
  pointer-events: none;

  > path {
    fill: #c0c0c0;
  }
}

.iconsDiv {
  .pointer {
    cursor: pointer;
  }

  .editIcon {
    margin-right: 5px;
  }
}
