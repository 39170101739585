.date-range-pickers {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;

  > div {
    width: 100%;
    &:nth-child(1) {
      margin-right: 8px;
      height: 40px;
      width: 8vw;
      border-radius: 4px;

    }
    &:nth-child(2) {
      height: 40px;
      width: 8vw;
      border-radius: 4px;
    }
  }
}
