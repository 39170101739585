@import '../../styles/brand-colors';

.change-log-list {
  padding: 16px 16px 0 16px;
  border: 1px solid $dusk-thirty;
  border-radius: 4px;

  .virtualized-table,
  .ReactVirtualized__Table,
  .ReactVirtualized__Table__headerColumn {
    padding-left: 0 !important;
  }

  .change-log-details {
    width: 100%;
    display: flex;

    & svg {
      cursor: pointer;
    }
  }

  .MuiTableCell-root {
    border: none !important;
  }

  .change-log-loading {
    height: 200px;
  }

}


.change-log-table {
  .ReactVirtualized__Table__rowColumn {

    > div {
      display: flex;
      justify-content: center;

      > span {
        margin-left: 0;
      }
    }
  }
}