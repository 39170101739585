@import '../../styles/brand-colors';

.form-header-top-bar {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 16px 0 16px;
  border-bottom: 1px solid $dusk-thirty;
  margin: 0 24px 0;

  h5 {
    font-size: 20px;
    padding-left: 8px;
  }

  svg {
    margin-right: 8px;

    & > path {
      fill: $black;
    }
  }

  .back-button {
    margin-top: 5px;
  }

  .action-buttons {
    margin-left: auto;

    .button-group {
      height: 40px;
      width: 163px;
      border-radius: 4px;
      margin-left: 8px;
    }

  }
}