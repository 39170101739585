@import '../../styles/brand-colors';

.home {
  display: flex;
  min-height: 100vh;
}

.tabs-container {
  padding: 16px 16px;
  width: 100%;
}