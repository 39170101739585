@import '../../styles/brand-colors';

.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  & .filter {
    display: flex;
    align-items: center;
    justify-content: center;

    & .MuiAutocomplete-root > .MuiFormControl-root {
      min-height: 20px !important;
      height: auto !important;

      .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        padding: 0 65px 0 0 !important;
      }
    }

    p {
      flex-grow: 1;
      text-align: center;
    }

    div {
      flex-grow: 3;
      text-align: center;
    }
  }

  & .name-filter {
    width: 35%;
  }

  & .status-filter {
    width: 10%;
  }

  & .region-filter {
    width: 30%;
  }

  & .year-filter {
    width: 10%;
  }

  & .scope-filter {
    width: 10%;
  }

  & .reload-icon {
    width: 5%;
  }
}