@import '../../styles/brand-colors';

.holiday-detail-information-container {
  border: 1px solid $midnight-30;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 24px;
  padding: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-width: 900px;

  .holiday-detail-loading {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader-dots {
      position: relative;
      top: 0;
      left: 0;
    }
  }

  .content-block-1 {
    display: flex;
    flex-direction: column;
    width: 33%;

    .date-range-selector {
      display: flex;
      flex-direction: row;

      > div {
        &:nth-child(1) {
          flex-grow: 1;
          width: 66.6%;
        }

        &:nth-child(2) {
          flex-grow: 1;
          width: 33.4%;
          margin-right: 15px;
        }
      }

      .date-picker {
        & .MuiFormControl-root {
          display: contents;
          justify-content: center;
        }

        & .MuiInputBase-input {
          width: 80%;
          justify-content: center;
        }

        & .MuiInputAdornment-root {
          width: 20%;

          button {
            display: contents;
          }
        }
      }
    }

    .text-area-config {
      display: flex;
      flex-direction: row;

      textarea {
        color: $dusk-light;
        height: 106px;
        width: 22vw;
        border-radius: 4px;
        margin-top: 16px;
        font-size: 14px;
      }

      &:focus {
        outline: $midnight-30;
        border: 2px solid $midnight-30;
      }
    }
  }

  .content-block-2 {
    display: flex;
    flex-direction: column;
    width: 33%;

    .select-group {
      display: flex;
      flex-direction: row;
      align-items: center;

      .select-size {
        height: 40px;
        width: 22vw;
        border-radius: 4px;

        .MuiAutocomplete-input {
          text-align: center;
        }
      }
    }
  }

  .content-block-3 {
    display: flex;
    flex-direction: column;
    width: 33%;

    .switch-group {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    input {
      height: 35px;
      width: 2.5vw;
      border-radius: 4px;
    }
  }
}

.holiday-detail-information-container.invalid-holiday-detail {
  border-color: red !important;
}

@media screen and (max-width: 1500px) {
  .holiday-detail-information-container {
    p {
      width: 100%;
      text-align: center;
    }

    .content-block-1 {
      .date-range-selector {
        flex-direction: column;

        > div {
          &:nth-child(1) {

          }

          &:nth-child(2) {
            flex-grow: 1;
            width: 100%;
          }
        }
      }

      .text-area-config {
        flex-direction: column;
        align-items: center;
      }
    }

    .content-block-2 {
      .select-group {
        flex-direction: column;
      }
    }

    .content-block-3 {
      .switch-group {
        flex-direction: column;
      }
    }
  }
}








