@import '../../styles/brand-colors';

.holiday-form-top-bar {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 16px 0 16px;
  border-bottom: 1px solid $dusk-thirty;
  margin: 0 24px 0;

  h5 {
    font-size: 20px;
    padding-left: 8px;
  }

  svg {
    margin-right: 8px;

    & > path {
      fill: $black;
    }
  }

  .add-holiday {
    margin-top: 5px;
  }

  .top-bar-buttons {
    margin-left: auto;

    .button-group {
      height: 40px;
      width: 163px;
      border-radius: 4px;
      margin-left: 8px;
    }

  }
}

.container-info {
  p {
    color: $dusk-light;
    font-size: 14px;
    width: 5vw;
  }
}


.holiday_top_content {
  border: 1px solid $midnight-30;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 24px;
  padding: 32px;
  display: flex;
  flex-direction: row;
  min-width: 900px;

  .holiday_top_inputs {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 33%;

    .inputs {
      width: 22vw;
      border-radius: 4px;
      align-items: center;
      height: 35px;
    }
  }

  .top-select-config {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 33%;
    margin-left: 10px;

    .select-format {
      height: 40px;
      width: 22vw;
      border-radius: 4px;
      margin-top: 5px;
    }
  }
}

.MuiFormControl-root {
  height: 37px !important;
}

.holiday-form-fields {
  border: 1px solid $midnight-30;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 24px;
  padding: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-width: 900px;

  .holiday-content-block1 {
    display: flex;
    flex-direction: column;
    width: 33%;

    .date-range-selector {
      display: flex;
      flex-direction: row;

      > div {
        &:nth-child(1) {
          flex-grow: 1;
          width: 66.6%;
        }

        &:nth-child(2) {
          flex-grow: 1;
          width: 33.4%;
          margin-right: 15px;
        }
      }

      .date-picker {
        & .MuiFormControl-root {
          display: contents;
          justify-content: center;
        }

        & .MuiInputBase-input {
          width: 80%;
          justify-content: center;
        }

        & .MuiInputAdornment-root {
          width: 20%;

          button {
            display: contents;
          }
        }
      }
    }

    .test-area-config {
      display: flex;
      flex-direction: row;

      textarea {
        color: $dusk-light;
        height: 106px;
        width: 22vw;
        border-radius: 4px;
        margin-top: 16px;
        font-size: 14px;
      }

      &:focus {
        outline: $midnight-30;
        border: 2px solid $midnight-30;
      }
    }
  }

  .holiday-content-block2 {
    display: flex;
    flex-direction: column;
    width: 33%;

    .select-group {
      display: flex;
      flex-direction: row;
      align-items: center;

      .select-size {
        height: 40px;
        width: 22vw;
        border-radius: 4px;

        .MuiAutocomplete-input {
          text-align: center;
        }
      }
    }
  }

  .holiday-content-block3 {
    display: flex;
    flex-direction: column;
    width: 33%;

    .switch-group {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    input {
      height: 35px;
      width: 2.5vw;
      border-radius: 4px;
    }
  }
}

.holiday-form-loading {
  border: 1px solid $midnight-30;
  border-radius: 4px;
  height: 600px;
}

@media screen and (max-width: 1500px) {
  .holiday_top_content {
    .holiday_top_inputs {
      flex-direction: column;

      p {
        width: 100%;
        text-align: center;
      }
    }

    .top-select-config {
      flex-direction: column;

      .select-format {
        margin-top: 0;
      }

    }
  }
  .holiday-form-fields {
    p {
      width: 100%;
      text-align: center;
    }

    .holiday-content-block1 {
      .date-range-selector {
        flex-direction: column;

        > div {
          &:nth-child(1) {

          }

          &:nth-child(2) {
            flex-grow: 1;
            width: 100%;
          }
        }
      }

      .test-area-config {
        flex-direction: column;
        align-items: center;
      }
    }

    .holiday-content-block2 {
      .select-group {
        flex-direction: column;
      }
    }

    .holiday-content-block3 {
      .switch-group {
        flex-direction: column;
      }
    }
  }
  .date-range-pickers > div:nth-child(1) {
    width: 10vw;
  }
  .date-range-pickers > div:nth-child(2) {
    width: 10vw;
  }
}

.alert-container {
  display: flex;

  .alert-message {
    flex-grow: 1;
    width: 100% !important;
    margin: 24px 24px 0 !important;
  }
}








