@import '../../styles/brand-colors';

.vacasa-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .vacasa-modal-card {
    width: 900px;
    height: 400px;
    display: flex;
    flex-direction: column;
    outline: none;

    .vacasa-modal-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      border-bottom: 1px solid $dusk-lightest;
      padding-left: 16px;
      padding-right: 16px;

      p {
        color: #707579;
        font-size: 18px;
        font-weight: bold;
        width: auto;
      }

      svg > path {
        fill: #bfc2c3;
      }
    }

    .vacasa-modal-body {
      height: 100%;
      padding-left: 8px;
      padding-right: 8px;
      overflow-y: auto;
    }
  }
}

.button-group-modal {
  margin-top: 40px;
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  .button-separation {
    margin-left: 10px;
  }
}