@import '../../styles/brand-colors';

.number-input {
  color: $dusk-lightest;
  border: 1px solid $dusk-thirty;
  border-radius: 5px 5px;
  text-align: center;
  width: 100%;

  &:focus {
    outline: $midnight-30;
    border: 2px solid $midnight-30;
  }

  &.error {
    border-color: $danger;
  }
  &::placeholder {
    color: $dusk-lightest;
  }
}

.changed {
  color: $dusk;

  &:disabled {
    color: $dusk-lightest;
  }
}
