@import '../../styles/brand-colors';

.MuiContainer-root {
  padding: 0 !important;
}

.nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 32px;
  background-color: $midnight-100;
  box-shadow: 2px 0 5px $black;

  .nav-bar-title {
    h1 {
      color: $dusk-one;
    }
  }

  .nav-bar-links {
    display: flex;
    flex-direction: row;

    div {
      &:after {
        content: '';
        top: 5px;
        bottom: 0;
        right: 0;
        margin-left: 8px;
        margin-right: 8px;
        border-right: 1px solid $gray-five;
      }

      &:last-child:after {
        display: none;
      }
    }

    a {
      color: $dusk-thirty;
      text-decoration: none;
    }

    .selected {
      color: $dusk-one;
      font-weight: bold;
    }
  }

  .temp-class {
    display: none;
  }
}
