@import '../../styles/brand-colors';

.holiday-list {
  padding: 2px 0;
}

.holiday-list-search {
  border-radius: 4px;
  border: 1px solid $dusk-thirty;
}

.add-button {
  padding: 10px 16px;
  display: flex;
  justify-content: flex-end;

  span {
    //display: flex;
    //display: none;
  }
}

.table-icons {
  display: flex;
  flex-direction: row;

  & svg {
    margin-left: 5px;
    margin-right: 5px;
  }

  .table-icon {
    padding-top: 9px;
  }
}

.virtualized-table {
  .ReactVirtualized__Table {
    .ReactVirtualized__Table__headerColumn {
      padding-left: 60px;
    }
  }
}

.holiday-list-table {
  border: 1px solid $midnight-30;
  border-radius: 4px;
  overflow: hidden;

  .virtualized-table-header.filtered-action-header {
    color: #4c5357 !important;
  }

  .virtualized-table-header {
    text-align: left;
  }
}

.holiday-list-loading {
  border: 1px solid $midnight-30;
  border-radius: 4px;
  height: 200px;
}

.add-date-button-list {
  display: flex;
  align-content: flex-end;
  border: 1px solid $midnight-40;
  border-radius: 4px;
  background-color: $white;
  padding: 8px 10px 0 10px;
  margin-top: 22px;

  span {
    font-weight: bold;
    font-size: 14px;
    color: #707579 !important;
  }

  .image-container {
    padding-left: 8px;
  }
}

.edit-button-list {
  display: flex;
  align-content: flex-end;
  border: 1px solid $midnight-40;
  border-radius: 4px;
  background-color: $white;
  padding: 8px 10px 0 10px;
  margin-top: 22px;
  margin-right: 4px;

  span {
    font-weight: bold;
    font-size: 14px;
    color: #707579 !important;
  }

  .image-container {
    padding-left: 8px;
  }
}

.add-holiday-img {
  margin-left: 15px;

  svg {
    margin-top: 5px;
  }
}

.alertmessage-module_messageBox__2juVW {
  margin: 0 !important;
}

a {
  text-decoration: none !important;
}